import {
  dataLayerUniqueTypeEvent,
  deleteLastSeenProducts,
  deleteSearchHistory,
  isValidArrayWithData,
  productClickTag,
  shelvesImpression
} from '@smu-chile/pkg-unimarc-hooks'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'

const isVisibleFunction = (isVisible: boolean) => {
  const backgroundSearch = isVisible ? 'white' : 'transparent'
  const borderSearch = isVisible ? '1px solid #C4C4C4' : '1px solid white'
  return { backgroundSearch, borderSearch }
}

export const searchProps = ({
  handleAdd,
  handleChange,
  handleChangeSearch,
  handleOnClick,
  handleRemove,
  inputAdornmentRef,
  isMobile,
  isVisible,
  isVisibleClearIcon,
  queryClient = null,
  result,
  router = null,
  setActiveMenu,
  setIsVisible,
  setIsFocused,
  setIsVisibleClearIcon,
  setValue,
  shelvesData,
  value
}) => {
  const { backgroundSearch, borderSearch } = isVisibleFunction(isVisible) || {}
  const site = 'Unimarc'
  // Get an array of items that return the search
  const suggestionsDataTopSearch =
    result?.mostWanted?.data?.suggestions?.map((item) => {
      return {
        text: `${item?.term}`,
        onClick: () => {
          return handleOnclickHeadSearch(item?.term)
        }
      }
    }) || []

  // Get an array of suggestions that return the search
  const suggestionsDataSearch =
    result?.searchSuggestions?.data?.suggestions?.map((item: string) => {
      return {
        text: `${item}`,
        onClick: () => {
          return handleOnclickHeadSearch(item)
        }
      }
    }) || []

  const searchHistoryData =
    (isValidArrayWithData(result?.searchHistory?.data) &&
      result.searchHistory.data.map((item: string) => {
        return {
          text: `${item}`,
          onClick: () => {
            return handleOnclickHeadSearch(item)
          }
        }
      })) ||
    []

  // Get an array of items with matches that return the search and return the structure required
  const intelligentData =
    shelvesData?.map((item) => {
      const maxQuantity =
        item?.cartLimit > 0
          ? item?.cartLimit
          : item?.sellers?.[0]?.availableQuantity
      return {
        ...item,
        img: item?.images[0],
        inOffer: item?.sellers[0]?.inOffer,
        itemId: item?.itemId,
        listPrice: item?.sellers[0]?.listPrice,
        maxQuantity,
        measurementUnit: item?.measurementUnit,
        price: item?.sellers[0]?.price,
        product: item,
        productUrl: formatProductUrl(item?.detailUrl),
        title: item?.['descriptionShort'] || item?.name,
        onClickProduct: () => {
          productClickTag({ product: item, isSearch: true, site })
        }
      }
    }) || []

  const headSearchData = () => {
    const isValid = intelligentData?.length > 0
    const suggestionsTitle = isValid ? 'Sugerencias' : 'Más buscados'
    const suggestionsData = isValid ? suggestionsDataSearch : {}

    return {
      cleanLastSeenProducts: () => {
        deleteLastSeenProducts()
        queryClient.refetchQueries('lastSeenProducts')
      },
      cleanSearchHistory: () => {
        deleteSearchHistory()
        queryClient.refetchQueries('searchHistory')
      },
      suggestionsTitle,
      suggestionsData: { suggestionsData, suggestionsDataTopSearch }
    }
  }

  const handleCloseSearch = () => {
    setIsVisible(false)
    setIsVisibleClearIcon(false)
  }

  const handleClearInputSearch = () => {
    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0])
      inputAdornmentRef.current.children[0].children[0].value = ''

    setValue('')
    setIsVisibleClearIcon(false)
  }

  const getUrlSearch = (term) => {
    return `/search?q=${term}`
  }

  const handleOnclickHeadSearch = (term: string) => {
    if (router) {
      router.push(getUrlSearch(term))
    } else {
      window.location.href = getUrlSearch(term)
    }

    dataLayerUniqueTypeEvent({
      event: 'search',
      search_term: term?.['term'] || term,
      search_type: intelligentData?.length > 0 ? 'Sugeridos' : 'Top Search'
    })
    setIsVisible(false)
  }

  const handleKeywordKeyPressSearch = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key == 'Enter') {
      handleOnclickSearch()
      setIsVisible(false)
    }
  }

  const handleOnBlur = () => {
    setIsFocused(false)
  }

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    if (!isMobile) {
      if (targetValue.length === 1 || targetValue.length === 2) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
        setActiveMenu('')
      }
    } else {
      setIsFocused(true)
      setIsVisible(true)
      setActiveMenu('')
    }
    setIsVisibleClearIcon(targetValue.length > 0)
    // search
    handleChangeSearch(targetValue.trimStart())
    setValue(targetValue)
  }

  const handleOnclickSearch = () => {
    if (value?.length === 0 || value?.match(/^\s*$/)?.index === 0) return
    if (router) {
      router.push(getUrlSearch(value))
    } else {
      window.location.href = getUrlSearch(value)
    }

    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0])
      inputAdornmentRef.current.children[0].children[0].blur()

    setIsVisibleClearIcon(false)
    setIsVisible(false)
  }

  const shelvesSearchImpressions = (shelves) => {
    if (!shelves[0]?.ref) return

    shelvesImpression({
      isSearch: true,
      parent: shelves[0].ref.parentNode.parentNode,
      shelves
    })
  }
  return {
    backgroundSearch,
    borderSearch,
    clearButton: isVisibleClearIcon,
    dropDownSearch: {
      bodySearch: {
        handleSearchImpressions: shelvesSearchImpressions,
        data: intelligentData,
        quantityButtonProps: {
          quantityButtonStyles: {
            maxHeight: '30px',
            maxWidth: '137px',
            minWidth: '137px',
            sizeCircle: 22
          },
          handleAdd,
          handleRemove,
          handleChange
        },
        handleOnClickShelfSearch: handleOnClick
      },
      headSearch: headSearchData(),
      isLoadingSearch:
        result?.intelligenceSearch?.isLoading || result?.topSearches?.isLoading,
      valueInputSearch: value,
      lastSeenProducts: result?.lastSeenProducts?.data || [],
      searchHistory: searchHistoryData || []
    },
    isVisibleDropDownSearch: isVisible,
    setIsFocusedInput: setIsFocused,
    inputAdornmentRef,
    searchPlaceholder: '¿Qué quieres comprar?',
    valueInputSearch: value,
    onBlurSearch: handleOnBlur,
    onChangeSearch: handleOnChangeSearch,
    onClearInput: handleClearInputSearch,
    onClickSearch: handleOnclickSearch,
    onCloseSearch: handleCloseSearch,
    onFocusSearch: handleOnChangeSearch,
    onKeyPressSearch: handleKeywordKeyPressSearch
  }
}
